import { gql } from '@apollo/client'

import { quotesFieldsForPartner } from '../../quotes/core/quotes.queries'
import { moveFieldsForPartner } from '../../moves/core/moves.queries'

export const commonJobRequestFields = `
id
createdAt
status
`

export const lightJobRequestFields = `
${commonJobRequestFields}
movingDate
movingTime
customerName
originCity
originRegion
destinationCity
destinationRegion
nbMen
nbTrucks {
  key
  value
}
residenceType
volumeCubicFeet
moveDurationSeconds
subtotal {
  price
  currency
}
`

export const jobRequestFieldsForPartner = `
${commonJobRequestFields}
move {
  ${moveFieldsForPartner}
}
quote {
  ${quotesFieldsForPartner}
}
message
response {
  createdAt
  message
}
`

export const getJobRequests = gql`
query GetJobRequests($companyBranchId: String!, $query: PaginatedQuery, $status: String) {
  getJobRequests(companyBranchId: $companyBranchId, query: $query, status: $status) {
    count
    data {
      ${lightJobRequestFields}
    }
    hasMore
  }
}
`

export const getJobRequest = gql`
query GetJobRequest($jobRequestId: String!) {
  getJobRequest(jobRequestId: $jobRequestId) {
    ${jobRequestFieldsForPartner}
  }
}
`
