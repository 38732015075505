import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type CloseJobFormValues } from './CloseJobForm.types'
import { useFloatValidator } from '../../invoice/ChargesFormContent'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const floatValidator = useFloatValidator()

  const hoursValidator = floatValidator({ min: 0, max: 15, maxDecimals: 1, required: false })
  const kmsValidator = floatValidator({ min: 0, max: 5000, maxDecimals: 1, required: false })
  const stairsValidator = floatValidator({ min: 0, max: 20, maxDecimals: 0, required: false })
  const priceValidator = floatValidator({ min: 0, max: 5000, maxDecimals: 2, required: false })

  const customChargesValidator = Yup.string().test(
    'validDescription',
    'Invalid description',
    (value, { createError, options, from }) => {
      const customCharge = parseFloat((from?.[0] as any)?.value?.customCharge ?? '0')
      if (customCharge > 0 && !value) {
        return createError({ message: t('formValidation.required') })
      }
      return true
    },
  )

  return Yup.object({
    movingLabour: hoursValidator,
    transportLabour: hoursValidator,
    packingLabour: hoursValidator,

    travelTime: hoursValidator,
    travelDistance: kmsValidator,
    transportDistance: kmsValidator,

    nbStairs: stairsValidator,
    customCharge: priceValidator,
    customChargeDescription: customChargesValidator,

    confirmation: Yup.boolean().oneOf([true], t('formValidation.required')).required(),
  }) as Yup.ObjectSchema<CloseJobFormValues, CloseJobFormValues>
}

export default useValidationSchema
