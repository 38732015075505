import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import {
  type InvoicePayload,
  InvoiceCategory,
} from '../../../../modules/invoices'
import { type FullMove } from '../../../../modules/adminMoves'
import { useMoveState } from '../../../../modules/moves'
import { Form, SubmitButton } from '../../../form'
import Paper from '../../../Paper'
import ChargesFormContent from '../ChargesFormContent'
import { type InvoiceFormValue } from './InvoiceForm.type'
import {
  invoiceToFormData,
  formatFormOutput,
} from './formDataBuilder'
import useValidationSchema from './validationSchema'
import Sidebar from './Sidebar'

type InvoiceFormProps = {
  move: FullMove
  category: InvoiceCategory
  onSubmit: (data: InvoicePayload) => Promise<void> | void
  onCancel?: () => Promise<void> | void
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  move,
  category,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  const {
    selectedQuote,
    promoCode: movePromoCode,
    currency,
  } = useMoveState(move)

  const isMainMoveInvoice = category === InvoiceCategory.move
  const promoCode = isMainMoveInvoice ? movePromoCode : undefined
  const moveSize = selectedQuote
    ? {
        nbMen: selectedQuote.resolvedNbMen,
        nbTrucks: selectedQuote.resolvedNbTrucks,
      }
    : undefined

  const schema = useValidationSchema()
  const form = useForm<InvoiceFormValue>({
    resolver: yupResolver(schema),
    defaultValues: invoiceToFormData(
      isMainMoveInvoice ? selectedQuote?.details ?? [] : [],
    ),
    mode: 'onTouched',
  })

  /**
   * format data and submit form
   */
  const handleSubmit = async (data: InvoiceFormValue) => {
    await onSubmit({
      category,
      promoCode,
      ...formatFormOutput(data, currency),
    })
  }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <div className="mt-2 flex flex-col justify-between gap-x-8 3xl:flex-row">

        <Paper className="h-auto max-w-full grow overflow-x-auto 3xl:h-[727px]">
          <ChargesFormContent
            editAll
            currency={currency}
            promoCode={promoCode}
            moveSize={moveSize}
            showSubtotal={false}
          />
        </Paper>

        <div className="shrink-0">
          <div className="3xl:sticky 3xl:top-0 3xl:w-[400px]">
            <Sidebar
              category={category}
              move={move}
              currency={currency}
              promoCode={promoCode}
            />
          </div>
        </div>
      </div>

      <div className="mb-1 mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.create') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default InvoiceForm
