import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

type ChargeValidationOptions = {
  min?: number
  max?: number
  required?: boolean
  maxDecimals?: number
}

export const useFloatValidator = () => {
  const { t } = useTranslation()

  return ({
    min = -99999,
    max = 99999,
    required = true,
    maxDecimals,
  }: ChargeValidationOptions = {}) => {
    return Yup.string().test(
      'floatValidator',
      t('formValidation.defaultShort'),
      (value, { createError, options, from }, ...y) => {
        if (value === undefined || value === '' || value === null) {
          return !required
        }
        // eslint-disable-next-line no-useless-escape
        const regex = min < 0 ? /^[\-0-9\.]*$/ : /^[0-9\.]*$/
        if (!regex.test(value)) {
          return false
        }

        const nbDots = (value.match(/\./g) ?? []).length
        if (nbDots > 1) {
          return false
        }
        if (maxDecimals !== undefined) {
          const nbDecimals = (value.split('.')?.[1] ?? '').length
          if (nbDecimals > maxDecimals) {
            return false
          }
        }

        const floatValue = parseFloat(value)

        if (isNaN(floatValue)) {
          return false
        }

        return floatValue >= min && floatValue <= max
      })
  }
}

const useChargesValidation = () => {
  const floatValidator = useFloatValidator()

  return Yup.array()
    .of(
      Yup.object({
        quantity: floatValidator({ min: 0.01, max: 99999 }),
        unitPrice: floatValidator({ min: -99999, max: 99999 }),
        item: Yup.string().required(),
      }),
    )
}

export default useChargesValidation
